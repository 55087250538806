<template>
   <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
         <div class="col-12">
            <div class="page-title-box">
               <h4 class="page-title">Update Meeting</h4>
            </div>
         </div>
      </div>
      <!-- end page title -->
      <place-holder v-if="loading"></place-holder>

      <div class="row" v-if="!loading">
         <div class="col-lg-6">
            <div class="card">
               <div class="card-body">
                  <div class="row">
                     <div class="col-md-4 mb-3">
                        <label class="form-label" for="start">
                           Date <span class="text-danger">*</span>
                        </label>
                        <flat-pickr
                           class="form-control"
                           placeholder="Select date"
                           v-model="meeting.date"
                           @on-change="getAvailableRooms"
                           :config="flatPickrDateConfig"
                        ></flat-pickr>

                        <v-errors
                           :serverErrors="errorFor('date')"
                           :vuelidateErrors="{
                              errors: v$.meeting.date.$errors,
                              value: 'Date',
                           }"
                        ></v-errors>
                     </div>

                     <div class="col-md-4 mb-3">
                        <label class="form-label" for="start">
                           Start <span class="text-danger">*</span>
                        </label>
                        <flat-pickr
                           class="form-control"
                           placeholder="Select start time"
                           v-model="meeting.start"
                           @on-close="getAvailableRooms"
                           :config="flatPickrTimeConfig"
                        ></flat-pickr>

                        <v-errors
                           :serverErrors="errorFor('start')"
                           :vuelidateErrors="{
                              errors: v$.meeting.start.$errors,
                              value: 'Start date',
                           }"
                        ></v-errors>
                     </div>

                     <div class="col-md-4 mb-3">
                        <label class="form-label" for="end">
                           End <span class="text-danger">*</span>
                        </label>
                        <flat-pickr
                           class="form-control"
                           placeholder="Select end time"
                           v-model="meeting.end"
                           @on-close="getAvailableRooms"
                           :config="flatPickrTimeConfig"
                           required
                        ></flat-pickr>

                        <v-errors
                           :serverErrors="errorFor('end')"
                           :vuelidateErrors="{
                              errors: v$.meeting.end.$errors,
                              value: 'End date',
                           }"
                        ></v-errors>
                     </div>
                  </div>

                  <div class="mb-3">
                     <label for="meeting-room" class="form-label"
                        >Room <span class="text-danger">*</span></label
                     >
                     <select
                        class="form-control"
                        :class="{
                           'p-invalid':
                              v$.meeting.roomId.$error || errorFor('roomId'),
                        }"
                        v-model="meeting.roomId"
                        id="meeting-room"
                        required
                     >
                        <option disabled selected>Select Room</option>
                        <option
                           v-for="room in rooms"
                           :key="room.id"
                           :value="room.id"
                        >
                           {{ room.name }}
                        </option>
                     </select>

                     <v-errors
                        :serverErrors="errorFor('roomId')"
                        :vuelidateErrors="{
                           errors: v$.meeting.roomId.$errors,
                           value: 'Room',
                        }"
                     ></v-errors>
                  </div>

                  <div class="mb-3">
                     <label for="product-name" class="form-label"
                        >Meeting Title <span class="text-danger">*</span></label
                     >
                     <input
                        type="text"
                        id="product-name"
                        class="form-control"
                        :class="{
                           'p-invalid':
                              v$.meeting.title.$error || errorFor('title'),
                        }"
                        placeholder="Title"
                        v-model="meeting.title"
                        required
                     />

                     <v-errors
                        :serverErrors="errorFor('title')"
                        :vuelidateErrors="{
                           errors: v$.meeting.title.$errors,
                           value: 'Meeting title',
                        }"
                     ></v-errors>
                  </div>

                  <div class="mb-3">
                     <label for="project" class="form-label"
                        >Projects <span class="text-danger">*</span></label
                     >
                     <v-select
                        multiple
                        v-model="meeting.projectsId"
                        label="name"
                        :options="projects"
                        :reduce="(name) => name.id"
                        required
                     ></v-select>

                     <v-errors
                        :serverErrors="errorFor('projectsId')"
                        :vuelidateErrors="{
                           errors: v$.meeting.projectsId.$errors,
                           value: 'Projects',
                        }"
                     ></v-errors>
                  </div>

                  <div>
                     <label for="meetingType" class="form-label"
                     >Meeting Type</label
                     >
                     <v-select
                        v-model="meeting.meetingTypeId"
                        label="name"
                        :options="meetingTypes"
                        :reduce="(name) => name.id"
                        :clearable="false"
                     ></v-select>
                  </div>
               </div>
            </div>
            <!-- end card -->
         </div>
         <!-- end col -->

         <div class="col-lg-6">
            <div class="card">
               <div class="card-body">
                  <div class="mb-3">
                     <label for="meeting-room" class="form-label"
                        >Internal Participants
                        <span class="text-danger">*</span></label
                     >
                     <v-select
                        multiple
                        v-model="meeting.intParticipantsId"
                        label="name"
                        :options="int_participants"
                        :class="{
                           'p-invalid':
                              v$.meeting.intParticipantsId.$error ||
                              errorFor('intParticipantsId'),
                        }"
                        required
                     ></v-select>

                     <v-errors
                        :serverErrors="errorFor('intParticipantsId')"
                        :vuelidateErrors="{
                           errors: v$.meeting.intParticipantsId.$errors,
                           value: 'Internal Participants',
                        }"
                     ></v-errors>
                  </div>

                  <div class="mb-3">
                     <label for="ext-participants" class="form-label">
                        External Participants(Optional)
                     </label>
                     <input
                        type="text"
                        class="form-control"
                        id="ext-participants"
                        placeholder="Enter title"
                        v-model="meeting.externalParticipants"
                     />
                  </div>

                  <div class="row mb-2">
                     <div class="col-md-2">
                        <div class="form-check form-check-success">
                           <input
                              class="form-check-input"
                              type="radio"
                              name="meetingStatus"
                              id="meetingFinished"
                              value="Finished"
                              v-model="meeting.status"
                           />
                           <label class="form-check-label" for="meetingFinished"
                              >Finished</label
                           >
                        </div>
                     </div>

                     <div class="col-md-2">
                        <div class="form-check form-check-warning">
                           <input
                              class="form-check-input"
                              type="radio"
                              name="meetingStatus"
                              id="meetingPending"
                              value="Pending"
                              v-model="meeting.status"
                           />
                           <label class="form-check-label" for="meetingPending"
                              >Pending</label
                           >
                        </div>
                     </div>

                     <div class="col-md-2">
                        <div class="form-check form-check-danger">
                           <input
                              class="form-check-input"
                              type="radio"
                              name="meetingStatus"
                              id="meetingCanceled"
                              value="Canceled"
                              v-model="meeting.status"
                           />
                           <label class="form-check-label" for="meetingCanceled"
                              >Canceled</label
                           >
                        </div>
                     </div>
                  </div>

                  <div class="mb-2">
                     <label for="meeting-remark" class="form-label"
                        >Remark(Optional)
                     </label>
                     <textarea
                        class="form-control"
                        rows="5"
                        id="meeting-remark"
                        v-model="meeting.remark"
                        placeholder="Please enter remarks"
                     ></textarea>
                  </div>
               </div>
            </div>
            <!-- end card -->
         </div>
         <!-- end col-->
      </div>
      <!-- end row -->

      <div class="row" v-if="!loading">
         <div class="col-12">
            <div class="text-center mb-3">
               <router-link :to="{ name: 'meetings' }">
                  <button type="button" class="btn w-sm btn-light waves-effect">
                     Cancel
                  </button>
               </router-link>
               <button
                  type="button"
                  class="btn w-sm btn-success waves-effect waves-light"
                  @click="updateMeeting"
                  v-if="!updating"
               >
                  Update
               </button>
               <button
                  class="btn btn-primary"
                  type="button"
                  disabled=""
                  v-if="updating"
               >
                  <span
                     class="spinner-border spinner-border-sm me-1"
                     role="status"
                     aria-hidden="true"
                  ></span>
                  Please wait...
               </button>
            </div>
         </div>
         <!-- end col -->
      </div>
      <!-- end row -->
   </div>
</template>

<script>
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";
import { useToast } from "vue-toastification";
import moment from "moment";

export default {
   setup() {
      const toast = useToast();
      return { v$: useVuelidate(), toast };
   },
   mixins: [validationErrors],
   components: {
      flatPickr,
      vSelect,
   },
   data() {
      return {
         int_participants: [],
         rooms: [],
         projects: [],
         meetingTypes: [],
         loading: false,
         meeting: {
            title: null,
            roomId: null,
            date: null,
            start: null,
            end: null,
            intParticipantsId: [],
            projectsId: [],
            meetingTypeId: null,
            externalParticipants: null,
            status: "",
            remark: null,
         },
         flatPickrDateConfig: {
            dateFormat: "Y-m-d",
            altInput: true,
            altFormat: "d-M-Y",
         },
         flatPickrTimeConfig: {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i:s",
            altInput: true,
            altFormat: "H:i",
            minTime: "08:00",
            maxTime: "17:30",
         },
         updating: false,
         baseUrl: process.env.VUE_APP_BASE_URL,
      };
   },
   validations() {
      return {
         meeting: {
            title: { required },
            roomId: { required },
            date: { required },
            start: { required },
            end: { required },
            intParticipantsId: { required },
            projectsId: { required },
         },
      };
   },
   methods: {
      async getMeetingDetails() {
         this.loading = true;
         await axios
            .get(`${this.baseUrl}admin/v1/meetings/${this.$route.params.id}`)
            .then((response) => {
               const result = response.data.data;
               this.meeting.title = result.title;
               this.meeting.roomId = result.room ? result.room.id : "";

               const startDate = new Date(result.start);
               const endDate = new Date(result.end);
               this.meeting.date = moment(startDate).format("YYYY-MM-DD");
               this.meeting.start = moment(startDate).format("H:mm:ss");
               this.meeting.end = moment(endDate).format("H:mm:ss");
               // console.log(this.meeting.end);
               this.meeting.intParticipantsId = result.users.map((x) => {
                  return {
                     id: x.id,
                     name: `${x.name}(${x.email})`,
                     business_unit_id: x.business_unit_id,
                  };
               });
               this.meeting.externalParticipants = result.external_participants;
               this.meeting.projectsId = result.project_ids;
               this.meeting.meetingTypeId= result.meeting_type_id;
               this.meeting.status = result.status;
               this.meeting.remark = result.remark;

               this.getAvailableRooms();
            });
         this.loading = false;
      },
      getProjects() {
         axios.get(`${this.baseUrl}admin/v1/projects`).then((response) => {
            this.projects = response.data.data;
         });
      },
      getMeetingTypes() {
         axios.get(`${this.baseUrl}admin/v1/meeting-types`).then((response) => {
            this.meetingTypes = response.data.data;
         });
      },
      getRooms() {
         axios.get(`${this.baseUrl}admin/v1/rooms`).then((response) => {
            this.rooms = response.data.data;
         });
      },
      getIntParticipants() {
         axios.get(`${this.baseUrl}admin/v1/users`).then((response) => {
            this.int_participants = response.data.data.map((x) => {
               return {
                  id: x.id,
                  name: `${x.name}(${x.email})`,
                  business_unit_id: x.business_unit_id,
               };
            });
         });
      },
      async getAvailableRooms() {
         if (this.meeting.start != null && this.meeting.end != null) {
            await axios
               .get(`${this.baseUrl}admin/v1/rooms/available`, {
                  params: {
                     date: this.meeting.date,
                     start: this.meeting.start,
                     end: this.meeting.end,
                     exceptMeetingId: this.$route.params.id,
                  },
               })
               .then((response) => {
                  this.rooms = response.data.data;
                  this.errors = null;
               })
               .catch((error) => {
                  this.errors = error.response.data.errors;
                  console.log(this.errors);
               });
         }
      },
      async updateMeeting() {
         const isFormCorrect = await this.v$.$validate();
         if (!isFormCorrect) return;

         // Check room again before store meeting
         await this.getAvailableRooms();
         if (!this.rooms.map((room) => room.id).includes(this.meeting.roomId)) {
            this.toast.error("Sorry! This room is not avaiable on this time");
            this.meeting.roomId = "";
            return;
         }

         this.updating = true;
         await axios
            .put(
               `${this.baseUrl}admin/v1/meetings/${this.$route.params.id}`,
               this.meeting
            )
            .then(() => {
               this.$router.push({ name: "meetings" });
               this.toast.success("Successfully meeting updated!");
            })
            .catch((error) => {
               this.errors = error.response.data.errors;
               this.toast.error("Something went wrong!");
            });

         this.updating = false;
      },
   },
   created() {
      this.getProjects();
      this.getRooms();
      this.getIntParticipants();
      this.getMeetingTypes();
      this.getMeetingDetails();
   },
};
</script>
